.btn{
  &[class*="btn-"]{
    margin-bottom: 1rem;
  }
}
.btn-group-vertical{
  .btn{
    &[class*="btn-"]{
      margin-bottom: 0;
    }
  }
}
.input-group{
  .btn{
    &[class*="btn-"]{
      margin-bottom: 0;
    }
  }
  margin-bottom: 1rem;  
}
// Custom round buttons
.btn-round{
  border-radius: $border-radius-extreme;
}

.btn:active, .btn.active{
	background-image: initial;
}
button:hover{
  cursor: pointer;
}

.btn-group>.btn.active{
  z-index: 0;
}

.btn.btn-primary,.btn-primary:disabled{
  background-color: $button-background-color !important;
  border-color: $button-border-color !important;
  color: $color !important;
}
.btn-lg{
  padding: 1rem 1.5rem;
  border-radius: 3px;
  font-size: 1rem;
}
.btn.btn-outline-secondary, .btn-outline-secondary:hover, .btn-outline-secondary:active{
  border: 2px solid $form-border-color;
  background-color: transparent !important;
  color: inherit !important;
}

.btn.btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary:focus, .btn-outline-primary:hover{
  border: 1px solid $button-border-color;
  background-color: transparent !important;
  color: $button-background-color !important;
}

.btn-primary:focus, .btn-primary.focus, .btn-outline-secondary:focus, .btn-outline-secondary.focus{
  box-shadow: none !important;
}

.btn-primary.btn-flat{
  background-color: transparent!important;
  color:  $text-muted !important;
}

.btn-outline-success:hover {
    background-color: transparent !important;
    color: #0CC27E !important;
}

/* Custom button groups */

.xt-group-btn .btn-primary {
    color:  $text-muted !important;
    background-color: $body-bg !important;
    border: 1px solid $form-border-color !important;
}

.xt-group-btn.btn-group>.btn.active {
    z-index: 1 !important;
}
.xt-group-btn.btn-group .btn{
    padding: 0.3rem 0.75rem;
}

.xt-group-btn .btn-primary.active {
    color: $body-color;
    background-color: rgba(151, 197, 245, 0.2) !important;
    border: 1px solid $button-border-color !important;
}

.xt-group-btn .btn-primary.focus {
    box-shadow: none !important;
}

.xt-group-btn input {
    display: none;
}

/*Custom button group end */

/* Assign hero props buttons */

.btn-add, .btn-minus{
	background: #f4f6fa;
    border: 1px solid #cccccc;
}
.btn-qty{
    background: #fff;
    border: 1px solid #cccccc;
}
.btn-outline-secondary.btn-delete{
    border: 1px solid #cccccc!important;
}

/* Assign hero props buttons */