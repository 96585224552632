@import "../../../../assets/sass/scss/variables";

$table-border-colour: $border-standard;
$table-background-colour: white;
$table-hover-colour: $slate;
$table-selected-colour: $blue;
$table-font-colour: $black;
$table-inner: $static-space-sml;

/**
 Explicit overrides for primeng's ui-table styles
 */
.ui-table-resizable>.ui-table-wrapper {
    min-height: 500px;
}

// Table Head
.ui-table .ui-table-thead > tr > th {
  border-color: $table-border-colour !important;
  background-color: $table-background-colour !important;
  padding: $table-inner;
}

.ui-table-resizable .ui-table-thead > tr > th {
  overflow: visible !important;
}

// Table Body
.ui-table .ui-table-tbody > tr {
  min-height: 40px;
  border-top: 2px solid $table-border-colour;
  padding: 0;
}

.ui-table .ui-table-tbody > tr:nth-child(even) {
  background-color: $table-background-colour !important;
}

.ui-table .ui-table-tbody > tr > td {
  height: 40px;
  background-color: inherit;
  border-color: $table-border-colour !important;
  color: $table-font-colour;
  padding: $table-inner !important;
}

.ui-table .ui-table-tbody > tr > td a {
  color: $black;
}

.ui-table .ui-table-tbody > tr.ui-state-highlight {
  background-color: $blue-light !important;
}

.ui-paginator {
  border: 0 !important;
}

.ui-chkbox .ui-chkbox-box {
  border: 1px solid $table-border-colour !important;
}

.ui-table-resizable > .ui-table-wrapper {
    //overflow-x: visible !important;
}

.ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
  color: $table-background-colour !important;
  line-height: 1;
}
p-table .ui-table-wrapper table tbody tr td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

p-table .ui-table .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: #7c87a0 !important;
}

p-table table td span {
  font-size: $font-size-root !important;
  @media
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 120dpi){
    font-size: 17px !important;
  }

  &.text-truncate {
    display: block;
  }
}
.prime-ng-table-no-display {
  display: none !important;
}

.switch-dropbox .dropdown-menu.show {
  min-width: 200px;
  padding: 5px;
  height: 400px;
  overflow-y: auto;
}
.switch-dropbox .dropdown-menu.show button {
  background: transparent;
  min-width: 200px;
  border: none !important;
}
.switch-dropbox .dropdown-menu.show button:focus {
  outline: none;
  box-shadow: none;
}
