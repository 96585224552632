/* You can add global styles to this file, and also import other style files */

/* You can add global styles to this file, and also import other style files */

/* @import url("assets/css/bootstrap.min.css"); */

/* @import url("../src/assets/css/prism.min.css");
@import url("../src/assets/css/sweetalert2.min.css");
@import url("../src/assets/css/bootstrap-timepicker.min.css");
@import url("../src/assets/css/wizard.css");
@import url("../src/assets/css/leaflet.css");
@import url("../src/assets/css/jquery-jvectormap-2.0.3.css");
@import url("../src/assets/css/ng2-toastr.min.css");
@import url("../src/assets/css/nouislider.min.css");
@import url("../src/assets/css/data-table/index.css");
@import url("../src/assets/css/data-table/icons.css");
@import url("../src/assets/css/demo.css"); */

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ngx-toastr/toastr.css";
@font-face {
  font-family: "Graphik Regular";
  src: url("assets/fonts/Graphik-Regular/Graphik-Regular.eot");
  src: url("assets/fonts/Graphik-Regular/Graphik-Regular.woff") format("woff"),
    url("assets/fonts/Graphik-Regular/Graphik-Regular.otf") format("opentype"),
    url("assets/fonts/Graphik-Regular/Graphik-Regular.svg#filename")
      format("svg");
}

@font-face {
  font-family: "Graphik Medium";
  src: url("assets/fonts/Graphik-Medium/Graphik-Medium.eot");
  src: url("assets/fonts/Graphik-Medium/Graphik-Medium.woff") format("woff"),
    url("assets/fonts/Graphik-Medium/Graphik-Medium.svg#filename") format("svg");
}

.z-index {
  z-index: auto;
}
.primary-color {
  color: #4a90e2;
}
.xt-production:last-child {
  border-bottom: 1px solid #dee2e6;
}

.xt-production a {
  color: #4a5160 !important;
}

.btn:focus,
.form-control:focus {
  box-shadow: none;
}

.xt-gallery-search {
  background-color: transparent !important;
  padding-top: 10px !important;
}

.col-buttons {
  top: 0;
  position: absolute;
  right: 0;
}

.xt-set-profile .btn {
  position: absolute;
  top: 20%;
  left: 4%;
}

.content-wrapper,
.content-wrapper .container-fluid {
  padding: 0;
}

.xt-omitted {
  background-color: #e6e2e2 !important;
}

.filter-dropdown {
  top: -5px !important;
}

/* sidebar queue */

.xt-sidebar-queue .media img {
  border-radius: 2px;
}

.xt-sidebar-queue:last-child {
  border-bottom: none !important;
}

.card-img span {
  word-break: break-word;
}

.xt-order-accordion .card {
  box-shadow: none !important;
  border: 1px solid #e2e6ee !important;
  border-radius: 3px;
  padding: 25px;
  margin-top: 20px;
}

.xt-order-accordion .card .card-header a {
  color: #7c87a0 !important;
  font-size: 15px;
}

.xt-order-accordion h3 {
  color: #4a5160;
  font-size: 16px;
  font-weight: 500;
}

.xt-order-accordion .card .card-header {
  padding: 0 !important;
}

.xt-order-accordion button.btn.btn-link.collapsed,
.xt-order-accordion button.btn.btn-link {
  text-align: left;
  color: #7c87a0 !important;
  text-decoration: none;
  width: 100%;
  white-space: normal;
}

.xt-order-accordion .form-group input[type="text"] {
  border-radius: 0.25rem !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

ngb-accordion.xt-order-accordion [role="tabpanel"] {
  padding: 0px !important;
}
/**
.dropup,
.dropdown {
  z-index: 999 !important;
}
**/
.xt-item-div-content {
  border: 1px solid #e2e6ee;
  border-radius: 3px;
  padding: 8px;
}

.xt-vl {
  height: 700px;
  border-right: 2px solid #e2e6ee;
}

.photo-establis-container {
  padding: 8px;
  border-radius: 3px;
  position: relative;
  background-color: #f7f8fa;
}

.xt-blue-btn-lg {
  border-radius: 3px;
  background-color: #4a90e2;
  padding: 1rem 1.5rem;
  color: #fff;
}

.mat-input-outer {
  display: table;
  width: 100%;
  position: relative;
  margin-bottom: 3rem;
}

.mat-input-outer input[type="text"],
.mat-input-outer input[type="email"],
.mat-input-outer input[ngbdatepicker] {
  height: 40px;
  border-radius: 0;
  border: none;
  width: 100%;
  padding: 0 !important;
}

form .form-control:focus {
  border-color: unset !important;
}

.xt-item-div-content img {
  height: 70px;
  width: 70px;
  border-radius: 3px;
}

.xt-status {
  font-size: 14px;
}

.xt-unwrap {
  color: #d0011b;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  background: transparent;
  border: none;
}

/*----------my css--------------*/

.ui-table .ui-table-thead > tr > th span:first-child {
  color: #7c87a0 !important;
}

.ui-table .ui-table-tbody .ui-selectable-row:hover {
  background: #f7f8fa !important;
}

.ui-table .ui-table-thead > tr > th:nth-child(1) span {
  color: #fff !important;
}

app-order-child-datatable .ui-table .ui-table-tbody > tr {
  background: #f4f4f4 !important;
}

.ui-table .ui-table-tbody tr:hover {
  background: #f7f8fa !important;
}

/*Search design start here*/

h6 {
  font-size: 0.85rem !important;
  color: #848fa6 !important;
}

.form-control {
  width: 90% !important;
}

.form-control placeholder {
  font-size: 0.75rem !important;
}

.dropdown-menu.show {
  padding: 5px;
}

button.menu-link {
  background: transparent;
  border: none;
  box-shadow: none;
  color: #7c87a0;
}

button.menu-link:focus {
  outline: none;
  box-shadow: none !important;
  border: none !important;
}

input[type="radio"],
span {
  color: #4e5564;
  font-size: 0.9rem;
  font-weight: 400;
  /* margin-left: 4px; */
}

/*Search design start here*/

img {
  image-orientation: from-image !important;
}

.xt-status-row a span {
  margin: 0px !important;
}

.xt-model-fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1002;
  background: white;
}

.make-link {
  cursor: pointer;
}

/* inventry grid */
.xt-card-img-rel img {
  position: relative;
}
.xt-card-img-rel:hover .xt-btn-position {
  display: block !important;
}
.xt-btn-position {
  display: none;
  position: absolute;
  top: 64%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

/* .xt-lightbox-img {
  max-width: 800px;
  width: auto;
  height: 100%;
  max-height: 455px;
} */

/* add new scene tag input */

.xt-tag-input .ng2-tag-input {
  border-bottom-color: #cdd0d9 !important;
}
.xt-tag-input .ng2-tag-input.ng2-tag-input--focused {
  border-bottom-color: #4a90e2 !important;
}
/* add new scene tag input */

/* new character css starts*/
.border-below .ng-select-container {
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  border-radius: 0px;
  border-bottom: 2px solid #cdd0d9;
}
.btn-background .ui-selectbutton .ui-button.ui-state-active {
  background-color: #007ad950 !important;
}
/* new character css ends*/

/* add new guest */
.add-guest .ng-select .ng-select-container {
  border-radius: 0 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #ccc !important;
}
/* add new guest */
/* tree view */
.child-loop .switch.checked {
  background: #4a90e2 !important;
}
/* tree view */
/* settings */
.set-select .ng-select .ng-select-container {
  border: 2px solid #cdd0d9;
  color: #75787d;
  padding: 1.75rem 0.75rem;
  border-radius: 3px;
}
/* settings */

@media screen and (max-width: 767px) {
  .xt-vertical-tabs .tab-content {
    width: 100% !important;
    float: right !important;
    border-left: none;
  }
  .xt-vertical-tabs ul.nav-tabs {
    width: 100%;
    display: block;
    float: none;
    padding: 0;
  }
}
@media screen and (max-width: 575px) {
  .res-media img {
    margin-bottom: 15px;
  }
  .res-media .media-body {
    padding-left: 0 !important;
  }
  .res-media {
    display: block;
    margin-bottom: 1.125rem;
  }
}

/* new css start */

.xt-pad-rmov .form-control {
  width: 100% !important;
}
.xt-pad-rmov table tbody tr td {
  padding-top: 0;
  padding-bottom: 0;
}

.xt-img-drop {
  transform: translate(-44px, 36px) !important;
  width: 400px;
}
.xt-img-add {
  position: relative;
  cursor: pointer;
}

.xt-img-btn {
  display: none;
  position: absolute;
  right: 23px;
  top: 13px;
}
.xt-img-add:hover .xt-img-btn {
  display: block;
}

.xt-attached-file {
  cursor: pointer;
}
.xt-attached-file:hover {
  background: #eee;
}
.xt-drop-width {
  width: 200px;
  font-size: 13px;
}
.my-control {
  padding: 0.375rem 0.75rem !important;
  border-width: 1px !important;
}
.xt-elips {
  display: none;
}
.xt-attached-file:hover .xt-elips {
  display: block;
}
.xt-pad-rmov label {
  margin-bottom: 0;
}

.xt-multi-tags
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value,
.xt-ngselect-dropdown-design.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: #ffffff !important;
  border: 1px solid #868e96 !important;
}
.xt-multi-tags
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label,
.xt-ngselect-dropdown-design.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label {
  font-size: 15px;
}
.xt-multi-tags
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon,
.xt-ngselect-dropdown-design.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon {
  color: #868e96 !important;
  border-right: none !important;
}
.xt-multi-tags .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 0;
}
.xt-multi-tags .ng-dropdown-panel-items.scroll-host::-webkit-scrollbar {
  width: 0px;
}
.is-selected {
  color: #4a90e2 !important;
}
.xt-display-btn:hover {
  color: #4a90e2 !important;
}
.xt-display-dropbtn:hover {
  background-color: #4a90e2 !important;
  color: #ffffff !important;
}
button:focus {
  outline: none;
}

.xt-form-btn {
  display: block;
  padding: 5px;
  background: #4a90e2;
  color: #fff;
  border-radius: 4px;
  text-align: center;
}
.xt-drop-width {
  width: 250px !important;
}
.my-drop-width {
  width: 380px;
  margin: 0 auto;
}
.xt-form-span .photo-establis-container {
  width: 100% !important;
}

.xt-attach-span .dropup,
.xt-attach-span .dropdown {
  z-index: auto !important;
}
.xt-multi-tags .ng-option.ng-option-marked span {
  padding: 5px;
  font-size: medium;
  line-height: 2;
}
.xt-multi-tags span.xt-elips {
  padding: 0px !important;
}
.my-label {
  overflow: hidden;
  width: 124px;
  text-overflow: ellipsis;
  font-size: 13.6px !important;
  line-height: 1 !important;
  padding: 6px 10px !important;
}
.xt-ngselect-dropdown-design .ng-option span.ng-option-label,
.xt-ngselect-dropdown-design .ng-option.ng-option-marked span.ng-option-label {
  border: 1px solid;
  border-radius: 4px;
  background: #fff;
  font-size: medium;
  padding: 1px 5px;
}
.xt-ngselect-dropdown-design
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-marked,
.xt-ngselect-dropdown-design
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected {
  background-color: #ccc3 !important;
}
.xt-ngselect-dropdown-design
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected
  .ng-option-label {
  font-weight: 400 !important;
}
