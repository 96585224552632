/* Upload container start */

.xt-upload-container {
    height: 150px;
    border: 2px dotted #CDD0D9;
    border-radius: 13px;
    margin-top: 20px;

    &:hover {
        border-color: #4A90E2;
        background-color: #97C5F5;
        cursor: pointer;

        .xt-upload-logo-icon {
            color: #4A90E2;
        }
    }
}

.xt-upload-logo-icon {
    text-align: center;
    margin-top: 50px;
    font-size: 2.5rem;
    color: #CDD0D9;
}


.xt-upload-photo{
    margin-top: 10px;
}
.file-droppa-container{
    width: 100% !important;
}

/* Upload container end */

/* Upload complete */

.xt-uploade-complete {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.photo-establis-container {
    padding: 8px;
    border-radius: 3px;
    position: relative;
    background-color: #F7F8FA;
}

.photo-establis-container:hover {
    background-color: rgba(151, 197, 245, 0.2);
}

.xt-upload-close {
    position: absolute;
    top: 30px;
    right: 15px;
    background: transparent;
    border: none;

    i, &i {
      height: 20px;
      width: 20px;
      text-align: center;
      background-color: #7C87A0;
      border-radius: 50%;
      color: #fff;
      padding: 2px;
    }

    &:hover i, &i:hover {
      background-color: #4A90E2;
    }
}

.xt-establis-photo {
    img {
      height: 70px;
      width: 70px;
      border-radius: 50%;
    }

    button {
      border: none;
      background: transparent;
    }
}

.xt-upload-img-link {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    overflow-wrap: break-word;
}

.file-droppa-btns{
    display: none !important;
}

.xt-edit-photo img{
    width: 80px;
    height: 80px;
    border-radius: 3px;
}

.xt-product-upload {
    border: 2px dotted #CDD0D9;
    border-radius: 2px;
    height: 100px;
    text-align: center;
}
