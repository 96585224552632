// Modals
// https://www.google.com/design/spec/components/dialogs.html#dialogs-specs

.modal-content {
  border-radius: $border-radius;
  .modal-footer {
    .btn {
      margin: 0;
    }

    .btn + .btn {
      margin-left: 8px;
    }
  }
}

.xt-full-screen-modal .modal-dialog{
    min-height: 100%;
    width: 100%;
    max-width: 100%;
    position: fixed;
    margin-top: 0;
}

.xt-full-screen-modal .modal-content{
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    border-radius: 0;
    border: 0;
    background-color: $color;
    overflow: auto;
}

.xt-full-screen-modal .modal-body{
    bottom: 60px;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
}
.xt-full-screen-modal .modal-header{
  box-shadow: $box-shadow;
}

.view-file .modal-body{
  background: $img-bg-color;
  position: inherit !important;
}