.badge{
	padding: 6px 10px;
	font-size: 85%;
	font-weight: 500;
	letter-spacing: 0.3px;
}

.badge-pill{
	padding: 3px 6px;
}

.xt-circle-text{
    position: absolute;
    right: 60px;
    top: 17px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    font-size: 15px;
    color: $color;
    background-color:  $text-muted;
    line-height: 36px;
    text-align: center;
}
a.card-img-badge{
    padding: 6px 5px;
    height: 30px;
    width: 32px;
    border-radius: 25px;
    opacity: 0.7;
    background-color: #7C87A0;
    margin-top: -42px;
    margin-right: 11px;
    color: $color !important;
    line-height: 17px;
}