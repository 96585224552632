.custom-control-label, .datatable-checkbox{
    margin: 0 12px;
    padding: 2px;
}

.custom-control-label::before, .custom-radio .custom-control-input:checked ~ .custom-control-label::after,
.datatable-checkbox{
  width: 20px !important;
  height: 20px !important;
  background-color: transparent;
  border: 2px solid $form-border-color;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.datatable-body .datatable-body-row.active .datatable-checkbox,
.datatable-header-cell[ng-reflect-all-rows-selected="true"] .datatable-checkbox {
  background-color: $button-background-color !important;
  border-color: $button-background-color !important;
  border-radius: 3px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: '';
  top: 6px;
  left: 8px;
  width: 5px;
  height: 11px;
  border: 1px solid $body-bg;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-image: none;
}

.custom-control-input:focus ~ .custom-control-label::before{
    box-shadow: none;
}

.custom-radio .custom-control-label::before {
  background: #ddd;
  border: none;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  background-color: $body-bg;
  border-radius: 50%;
  border: 6px solid $button-background-color;
}

.custom-control-input:active ~ .custom-control-label::before{
    background-color: #ddd;
}

// Datatable checkbox

.datatable-checkbox input {
    position: absolute;
    opacity: 0;
}

.datatable-checkbox{
    position: relative;
    border-radius: 3px;
}

.datatable-body .datatable-body-row.active .datatable-checkbox:after,
.datatable-header-cell[ng-reflect-all-rows-selected="true"] .datatable-checkbox:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 5px;
    width: 6px;
    height: 12px;
    border: 1px solid $body-bg;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

// Custom radio buttons

.xt-custom-radio .custom-radio{
  border-radius: 3px;
  background-color: #F7F8FA;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
  padding: 7px 0px 7px 25px;
}

.xt-custom-radio .custom-radio .custom-control-label::before{
  background-color: transparent !important;
  border: 2px solid $form-border-color !important;
}

.xt-custom-radio .custom-control-label::before,
.xt-custom-radio  .custom-control-label::after{
  top: 10px;
  left: 10px;
}

/* Checkboxes on imgae hover */

.xt-custom-control-label::before{
    width: 25px !important;
    height: 25px !important;
    background-color: white !important;
    border: 1px solid #868e96 !important;
}
.custom-checkbox .custom-control-input:checked ~ .xt-custom-control-label::after{
    top: 9px;
    left: 10px;
}
.btn-outline-secondary.btn-horizontal{
    background-color: white !important;
    border: 1px solid #868e96 !important;
}

/* Checkboxes on imgae hover */