.xt-dot {
  height: 12px;
  width: 12px;
  background-color: #E2E6EE;
  border-radius: 50%;
  display: inline-block;
}

.xt-custom-tabs .nav-tabs .nav-link.active .xt-dot {
  height: 16px;
  width: 16px;
  background-color: $button-background-color;
}

.xt-custom-tabs .nav-tabs,
.xt-custom-tabs .nav-tabs .nav-link {
  border-bottom: none;
  border: none;
}

.xt-production-img {
  width: 200px;
  line-height: 200px;
  border-radius: 100%;
  background-color: $img-bg-color;
  text-align: center;
}

/*---Modal tabs start---*/

.xt-modal-tabs .nav-tabs .nav-link {
  color: #7C87A0 !important;
  font-size: 14px !important;
  padding: 0.5rem 5.7rem !important;
  margin-right: 0 !important;
  border: none !important;
  font-weight: 400;
}

.xt-modal-tabs .nav-tabs {
  border-bottom: 2px solid #E2E6EE;
}

.xt-modal-tabs.checkin-items .nav-tabs {
  border-bottom: 0;
}

.xt-modal-tabs .nav-tabs .nav-link.active {
  color: $body-color;
  border-bottom: 3px solid $form-color !important;
}

.xt-tabs.xt-modal-tabs .nav-tabs .nav-link {
  padding: 0.5rem 15px !important;
}

/*---Modal tabs End---*/

/* Tabs content */

.info-tab-title {
  height: 36px;
  padding-left: 12px;
  border-radius: 3px;
  background-color: #f5f7fa;
}

.info-tab-title span {
  line-height: 35px;
  color: $footer-color;
  font-size: 15px;
  font-weight: 400;
}

.information-tab h6 {
  color: $footer-color;
}

.notes .card {
  background-color: $img-bg-color;
  box-shadow: none;
  border-radius: 3px;
}

/* Tabs content */

/* Character profile change tab content (Image card styling) start*/

.card-changes img {
  width: 100%;
  border-radius: 3px 3px 0 0;
}

.card-changes {
  // height: 315px !important;
}

.hero-props-img {
  // height: 266px;
  border: 1px solid #E2E6EE;
  border-radius: 3px;
}

.img-card-title {
  height: 64px !important;
  border-top: 1px solid #E2E6EE;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: .5s ease;
}

.card-img:hover .overlay,
.edit-img:hover .overlay {
  opacity: 1 !important;
}

.checkbox-container {
  top: 5px;
  left: 25px !important;
  position: absolute;
}

.btn-horizontal {
  right: 25px;
  position: absolute;
}

.xt-change-profile-tabs .btn-horizontal {
  right: 25px !important;
  position: absolute;
}

.active.card-img .overlay,
.active.card-img .continuity-img,
.active.card-img .img-card-title {
  opacity: 1 !important;
  background-color: #E3F2FD !important;
}

/* Character profile change tab content (Image card styling) end*/

/* Setting page vetical tab style start here */

.xt-vertical-tabs ul.nav-tabs {
  width: 25%;
  display: block;
  float: left !important;
  padding-right: 30px;
}

.xt-vertical-tabs .tab-content {
  width: 75% !important;
  float: right !important;
  border-left: 1px solid $form-border-color;
}

.xt-vertical-tabs .nav-tabs {
  border-bottom: none !important;
}

.xt-vertical-tabs .nav-tabs .nav-link {
  color: $body-color;
  padding: 16px !important;
  border: 1px solid $form-border-color;
  border-radius: 4px;
}

.xt-vertical-tabs .nav-tabs .nav-link:focus {
  transition: all 0.5s ease 0s;
}

.xt-vertical-tabs .nav-tabs .nav-link.active {
  background-color: rgba(151, 197, 245, 0.2);
  color: $form-color !important;
  transition: all 0.5s ease 0s;
  border: none;
}

.xt-vertical-tabs .nav-tabs .nav-item {
  letter-spacing: 0.5px;
  margin-bottom: 12px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
}

.xt-tabpanel header {
  color: $body-color;
  font-size: 28px;
  line-height: 31px;
  margin-bottom: 15px;
}

.xt-vertical-tabs hr {
  margin: 50px 0;
  border-color: $form-border-color !important;
}

.show-notification {
  padding: 14px !important;
  height: 65px !important;
}

/* Setting page vetical tab style end here */


@media screen and (max-width:767px) {
  .xt-production-img {
    margin: 0 auto;
  }

  .m-center {
    float: none;
  }
}
